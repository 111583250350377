label.mobil-menu-button {
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;
}

label.mobil-menu-button span:not(:last-child) {
  margin-bottom: 6px;
}

label.mobil-menu-button span {
  border-radius: 0px;
  height: 2px;
  min-height: 2px;
  max-height: 2px;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  width: 100%;
}

input#mobilHamburger[type='checkbox'] {
  display: none;
}

input#mobilHamburger[type='checkbox']:checked ~ span:nth-of-type(1) {
  transform-origin: left;
  transform: rotateZ(45deg) translateY(-1.6px) translateX(0.3px);
}

input#mobilHamburger[type='checkbox']:checked ~ span:nth-of-type(2) {
  transform-origin: center;
  opacity: 0;
  transform: translateX(50px);
}

input#mobilHamburger[type='checkbox']:checked ~ span:nth-of-type(3) {
  transform-origin: left;
  transform: rotateZ(-45deg) translateY(4px) translateX(-2px);
}

.law-body * {
  font-family: Mark !important;
}

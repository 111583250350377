@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.jobs-table::-webkit-scrollbar {
  width: 5px;
}
.jobs-table::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: rgb(243, 243, 243);
}
.jobs-table::-webkit-scrollbar-thumb {
  background-color: #009ade;
  border-radius: 100px;
}

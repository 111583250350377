.anim-fade-in {
  animation-name: animFadeIn;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes animFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ???????????????????? */

.anim-fade-up {
  animation-name: animFadeUp;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes animFadeUp {
  from {
    transform: translateY(100px);
    opacity: 0 !important;
  }
  to {
    transform: translateY(0px);
    opacity: 1 !important;
  }
}
